<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Crédito | Compra Avulsa</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
        <div class="box-body">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          ></loading>
          <div class="col-sm-12">
            <div class="wizard">
              <b-card class="shadow-sm p-3 mb-5 bg-white rounded">
                <b-tabs v-model="tabIndex" content-class="mt-3" align="center">
                  <b-tab style="border: none;">
                    <template #title>
                      <b-card style="border: none;">
                        <b-card-title style="text-align: center; border: none;"><img :src="oneCircleIcon"></b-card-title>
                        <b-card-text class="tab-title"> Dados da compra</b-card-text>
                      </b-card>
                    </template>
                    <b-container>
                      <div class="col-sm-12">
                        <h3 class="tab-title2">
                          Selecione o valor do crédito a ser adquirido:
                        </h3>
                          <b-form-group>
                            <InputRangeBar ref="inputRanger" @onInputRangeBarChanged="setInputRangeData($event)"></InputRangeBar>
                          </b-form-group>
                        <b-row>
                          <b-col>
                            <b-card style="border: none;">
                              <b-card-title class="info-box"><img :src="creditBlackIcon">   Valor</b-card-title>
                              <b-card-text class="info-box" style="margin-left: 40px"> {{"R$" + Number(paymentValue) + ",00"}}</b-card-text>
                            </b-card>

                          </b-col>
                          <b-col >
                            <b-card style="border: none;">
                              <b-card-title class="info-box"><img :src="timeIcon">   Minutos</b-card-title>
                              <b-card-text class="info-box" style="margin-left: 40px"> {{requiredTime}}</b-card-text>
                            </b-card>
                          </b-col>
                          <b-col>
                            <b-card style="border: none;">
                              <b-card-title class="info-box"><img :src="planBlackIcon">   Plano</b-card-title>
                              <b-card-text class= "info-box" style="margin-left: 40px; font-size: 14px; font-style: normal; font-weight: 400"> Não há plano contratado.
                                O valor por minuto é R$ {{minuteValue}}</b-card-text>
                            </b-card>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="col-sm-12 bottom-bar">
                        <b-form inline>
                        <b-form-checkbox class="multichannelAudio mt-3" value="accepted" v-model="creditApiChecked" unchecked-value="not_accepted" @change="onChangeCreditApiCheckBox($event)">
                          Crédito API
                        </b-form-checkbox>
                        <img src="@/assets/icons/icone_i.svg" v-b-tooltip.hover.right :title="creditInformation" ref="showAfterFile" style="margin-top: 5px; margin-left: 8px;"/>
                        </b-form>
                        <ul class="list-inline float-right">
                          <li class="list-inline-item">
                            <b-button class="button-style" @click="goToUserFormTab()"
                              >Avançar</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </b-container>
                  </b-tab>
                  <b-tab
                    :disabled="isUserFormTabDisabled"
                  >
                    <template #title>
                      <b-card style="border: none;">
                        <b-card-title style="text-align: center; border: none;"><img :src="isUserFormTabDisabled ? twoCircleIconGray : twoCircleIcon"></b-card-title>
                        <b-card-text class="tab-title"> Dados do comprador</b-card-text>
                      </b-card>
                    </template>
                    <UserForm ref="UserForm" :readonly="false"
                      @backToInitialTab="backToInitialTab($event)"
                      @handleUserFormData="handleUserFormData($event)"
                    />
                  </b-tab>
                  <b-tab
                    :disabled="isPaymentTabDisabled"
                  >
                    <template #title>
                      <b-card style="border: none;">
                        <b-card-title style="text-align: center; border: none;"><img :src="isPaymentTabDisabled ? treeCircleIconGray : treeCircleIcon"></b-card-title>
                        <b-card-text class="tab-title"> Dados do pagamento</b-card-text>
                      </b-card>
                    </template>
                    <PaymentForm :readonly="false" ref="PaymentForm"
                     @backToUserFormTab="backToUserFormTab($event)"
                     @handlePaymentFormData="handlePaymentFormData($event)"
                    />
                  </b-tab>
                  <b-tab :disabled="isSummaryTabDisabled">
                    <template #title>
                      <b-card style="border: none;">
                        <b-card-title style="text-align: center; border: none;"><img :src="isSummaryTabDisabled ? fourCircleIconGray : fourCircleIcon"></b-card-title>
                        <b-card-text class="tab-title"> Resumo</b-card-text>
                      </b-card>
                    </template>
                    <b-container>
                      <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="default"
                              >Dados da Compra</b-button
                            >
                          </b-card-header>
                          <b-collapse
                            id="accordion-1"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-row>
                              <b-col>
                                <b-card style="border: none;">
                                  <b-card-title class="info-box"><img :src="creditBlackIcon">   Valor</b-card-title>
                                  <b-card-text class="info-box" style="margin-left: 40px"> {{"R$" + Number(paymentValue) + ",00"}}</b-card-text>
                                </b-card>

                              </b-col>
                              <b-col >
                                <b-card style="border: none;">
                                  <b-card-title class="info-box"><img :src="timeIcon">   Minutos</b-card-title>
                                  <b-card-text class="info-box" style="margin-left: 40px"> {{requiredTime}}</b-card-text>
                                </b-card>
                              </b-col>
                              <b-col>
                                <b-card style="border: none;">
                                  <b-card-title class="info-box"><img :src="planBlackIcon">   Plano</b-card-title>
                                  <b-card-text class= "info-box" style="margin-left: 40px; font-size: 14px; font-style: normal; font-weight: 400"> Não há plano contratado.
                                    O valor por minuto é R$ {{minuteValue}}</b-card-text>
                                </b-card>
                              </b-col>
                            </b-row>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="default"
                              >Dados do Comprador</b-button
                            >
                          </b-card-header>
                          <b-collapse
                                  id="accordion-2"
                                  visible
                                  accordion="my-accordion"
                                  role="tabpanel"
                          >
                          <UserForm :readonly="true" ref="UserFormResume" :load="loadUserFormResume()"
                                    @backToInitialTab="backToInitialTab($event)"
                                    @handleUserFormData="handleUserFormData($event)"
                            />
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-3 variant="default"
                              >Dados do Pagamento</b-button
                            >
                          </b-card-header>
                          <b-collapse
                                  id="accordion-3"
                                  accordion="my-accordion"
                                  role="tabpanel"
                          >
                            <PaymentForm ref="PaymentFormResume" :readonly="true" :load="loadPaymentFormResume()"
                                    @backToUserFormTab="backToUserFormTab($event)"
                                    @handlePaymentFormData="handlePaymentFormData($event)"
                            />
                          </b-collapse>
                          <div class="col-sm-12 bottom-bar">
                            <ul class="list-inline float-right">
                              <li class="list-inline-item" id="voltar-compra">
                                <b-button
                                        class="button-back-style"
                                        @click="backToPreviousTab()"
                                >Voltar</b-button
                                >
                              </li>
                              <li class="list-inline-item">
                                <b-button
                                        class="button-style"
                                        @click="submitPaymentData()"
                                >Comprar</b-button
                                >
                              </li>
                            </ul>
                          </div>
                        </b-card>
                      </div>
                    </b-container>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { storeService } from '../../../services/store.service';
  import { utilsService } from '../../../services/utils.service';
  import Loading from 'vue-loading-overlay';
  import PaymentForm from '../Forms/PaymentForm.vue';
  import UserForm from '../Forms/UserForm.vue';
  import InputRangeBar from '../StoreDetached/InputRangeBar.vue';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapActions } from 'vuex';
  import creditBlackIcon from '../../../assets/icons/credit_black.svg';
  import timeIcon from '../../../assets/icons/timer.svg';
  import planBlackIcon from '../../../assets/icons/plan_black.svg';
  import oneCircleIcon from '../../../assets/icons/one_circle.svg';
  import twoCircleIcon from '../../../assets/icons/two_circle.svg';
  import treeCircleIcon from '../../../assets/icons/tree_circle.svg';
  import fourCircleIcon from '../../../assets/icons/four_circle.svg';
  import twoCircleIconGray from '../../../assets/icons/two_circle_gray.svg';
  import treeCircleIconGray from '../../../assets/icons/tree_circle_gray.svg';
  import fourCircleIconGray from '../../../assets/icons/four_circle_gray.svg';

  export default {
    components: {
      Loading,
      PaymentForm,
      UserForm,
      InputRangeBar
    },
    data () {
      return {
        creditBlackIcon,
        timeIcon,
        planBlackIcon,
        oneCircleIcon,
        twoCircleIcon,
        treeCircleIcon,
        fourCircleIcon,
        twoCircleIconGray,
        treeCircleIconGray,
        fourCircleIconGray,
        paymentValue: 90,
        requiredTime: 60,
        minuteValue: 1.50,
        isUserFormTabDisabled: true,
        isPaymentTabDisabled: true,
        isSummaryTabDisabled: true,
        isLoading: false,
        fullPage: true,
        tabIndex: 0,
        creditApiChecked: 'not_accepted',
        creditInformation: 'Selecione essa opção caso for adquirir créditos somente para transcrição via API - sem disponibilidade no aplicativo de edição',
        user: {
          name: '',
          type: 'Pessoa Física',
          personalId: '',
          phone: '',
          zipCode: '',
          address: '',
          complement: '',
          number: '',
          district: '',
          email: '',
          state: '',
          city: ''
        },
        card: {
          number: '',
          brand: '',
          expirationMonth: '',
          expirationYear: '',
          securityCode: '',
          holderName: ''
        }
      };
    },
    methods: {
      ...mapActions('account', ['balance']),
      async goToUserFormTab () {
        await (this.isUserFormTabDisabled = false);
        this.goToNextTab();
      },
      goToNextTab () {
        this.tabIndex++;
      },
      setInputRangeData (event) {
        this.paymentValue = event.paymentValue;
        this.requiredTime = event.requiredTime;
        this.minuteValue = this.$refs.inputRanger.paymentValueInfo();
      },
      onChangeCreditApiCheckBox (checked) {
        if (checked === 'accepted') {
          this.$refs.inputRanger.setApiValue();
          this.minuteValue = this.$refs.inputRanger.paymentValueInfo();
        } else {
          this.$refs.inputRanger.setNormalValue();
          this.minuteValue = this.$refs.inputRanger.paymentValueInfo();
        }
      },
      loadUserFormResume () {
        if (this.$refs.UserForm) {
          const userInfo = this.$refs.UserForm.userInfo();
          this.$refs.UserFormResume.loadUserInfo(userInfo);
        }
      },
      loadPaymentFormResume () {
        if (this.$refs.PaymentForm) {
          const paymentInfo = this.$refs.PaymentForm.paymentInfo();
          this.$refs.PaymentFormResume.loadPaymentInfo(paymentInfo);
        }
      },
      backToInitialTab (event) {
        if (event.initialTab === true) {
          this.backToPreviousTab();
        }
      },
      backToPreviousTab () {
        this.tabIndex--;
      },
      async handleUserFormData (event) {
        if (event.nextTab === true) {
          utilsService.setUserFormData(this.user, event);
          await (this.isPaymentTabDisabled = false);
          this.goToNextTab();
        }
      },
      backToUserFormTab (event) {
        if (event.userFormTab === true) {
          this.backToPreviousTab();
        }
      },
      async handlePaymentFormData (event) {
        if (event.nextTab === true) {
          utilsService.setCardFormData(this.card, event);
          await (this.isSummaryTabDisabled = false);
          this.goToNextTab();
        }
      },
      async submitPaymentData () {
        const createPaymentProps = { api: this.creditApiChecked !== 'not_accepted', user: this.user, card: this.card, loggedEmail: this.$store.state.account.user.username, paymentValue: this.paymentValue, secondsQuantity: this.requiredTime * 60, extraProp: { purchaseType: 'detached' } };
        const userData = utilsService.createPaymentData(createPaymentProps);
        this.isLoading = true;
        const res = await storeService.requestTransaction(userData);
        this.isLoading = false;
        // TODO: test after
        res.resultCreditTransaction === true
                ? this.redirectAfterSuccessOperation()
                : this.$toast.open({ message: `Falha na transação! ${res.msg}`, type: 'error' });
      },
      redirectAfterSuccessOperation () {
        this.balance();
        this.$router.push({ name: 'sucessfulPurchase' });
      }
    },
    computed: {
      completeExpirationDate () {
        return `${this.card.expirationMonth}/${this.card.expirationYear}`;
      }
    }
  };
</script>

<style lang='scss' scoped>

.nav-tabs {
  border-bottom: 0px solid transparent;
}
.nav-tabs .nav-link {
  border: 0px solid transparent;
}

.section-title h2 {
  margin-left: 5px;
}

.section-title {
  height: 90px;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.info-box {
  margin-left: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  color: #495057;
}

.form-font {
  height: 21px;
  width: 34px;
  left: -1031px;
  top: -8372.5px;
  border-radius: nullpx;

}

.wizard {
  margin: 0px auto;
  background: #fff;
}

h3,
.h3 {
  font-size: 24px;
}

.button-style {
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  border-radius: 4px;
  background: #207BDD;
}

.button-back-style {
  /* Rectangle 3926 */
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  color: #207bdd;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #207BDD;
  box-sizing: border-box;
  border-radius: 4px;

}

.tab-title {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #6278A3;
}

.tab-title2 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #6278A3;
  padding-top: 10px;
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-box-info {
  color: #7952b3;
  height: 138px;
  margin-top: -19px;
  margin-left: 0px;
  margin-bottom: -21px;
}
.box-icon {
  color: #e0e0e0;
  width: 38.5667px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-inside-cards {
  padding-top: 20px;
}

.card-number {
  font-weight: bold;
  font-size: 18px;
}
.form-group {
  margin-bottom: 3px !important;
}

.card-body {
  background-color: #fff;
}

.control-label {
  font-size: 12px !important;
}

.payment-label {
  padding-left: 100px;
}

.bottom-bar {
  margin-top: 20px;
}
</style>
