<template>
  <b-col class="col-sm-12">
    <div>
      <b-form-input
        v-model="paymentValue"
        type="range"
        :min="minValue"
        max="500"
      ></b-form-input>
    </div>
  </b-col>
</template>

<script>
  export default {
    data () {
      return {
        minValue: 90,
        paymentValue: 90,
        requiredTime: 60,
        constantCalc: 1.5,
        apiChecked: false
      };
    },
    methods: {
      setNormalValue () {
        this.minValue = 90;
        this.paymentValue = 90;
        this.requiredTime = 60;
        this.constantCalc = this.paymentValue / this.requiredTime;
        this.apiChecked = false;
      },
      setApiValue () {
        this.minValue = 9;
        this.paymentValue = 9;
        this.requiredTime = 60;
        this.constantCalc = this.paymentValue / this.requiredTime;
        this.apiChecked = true;
      },
      paymentValueInfo () {
        return this.constantCalc;
      }
    },
    watch: {
      paymentValue () {
        const calculatedRequiredTime = this.paymentValue / this.constantCalc;
        this.requiredTime = Math.round(calculatedRequiredTime);
        this.$emit('onInputRangeBarChanged', { paymentValue: this.paymentValue, requiredTime: this.requiredTime });
      }
    }
  };
</script>

<style lang='scss' scoped>
.section-title h2 {
  margin-left: 5px;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0px;
}

.wizard {
  margin: 0px auto;
  background: #fff;
}

h3,
.h3 {
  font-family: "Open Sans Regular" !important;
  font-size: 24px;
}

.tab-title {
  margin-top: -20px;
  margin-bottom: 20px;
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-box-info {
  color: #7952b3;
  height: 138px;
  margin-top: -19px;
  margin-left: 0px;
  margin-bottom: -21px;
}
.box-icon {
  color: #e0e0e0;
  width: 38.5667px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-inside-cards {
  padding-top: 20px;
}

.card-number {
  font-weight: bold;
  font-size: 18px;
}
.form-group {
  margin-bottom: 3px !important;
}

.control-label {
  font-size: 12px !important;
}

.payment-label {
  padding-left: 100px;
}

.bottom-bar {
  margin-top: 20px;
}
</style>
